@import '~antd/dist/antd.dark.css';
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #0a0005;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-card-meta-title, .ant-card-meta-description {
  text-align: center;
}

.ant-card-meta-description {
  text-overflow: ellipsis; 
  overflow: hidden; 
  white-space: nowrap;
}

.ant-card-actions:hover {
  background: #ffee0a36;
  transition: background 0.5s ease-in-out;
}

.ant-card-actions:hover > li, .ant-card-actions:hover > li span {
  color: #f5d500;
}

.ant-anchor-link {
  font-size: 1.2em;
}

.ant-anchor .ant-anchor-link {
  padding: 7px 16px 27px 7px;
}

.ant-card-cover > img {
  max-height: 300px;
  object-fit: cover;
  cursor: pointer;
}

.ant-card-cover > img:hover {
  -webkit-animation: flash 1.5s;
	animation: flash 1.5s;
}

.ant-card-bordered .ant-card-cover {
  overflow: hidden;
}

@-webkit-keyframes flash {
	0% {
		opacity: .4;
	}
	100% {
		opacity: 1;
	}
}
@keyframes flash {
	0% {
		opacity: .4;
	}
	100% {
		opacity: 1;
	}
}

body {
  font-family: 'Tajawal', Tahoma, arial;
}

.ant-divider-horizontal.ant-divider-with-text {
  font-size: 1.5em;
}

.ant-layout-header {
  height: auto !important;
  background: #060005 !important;
}

.logo {
  text-align: center;
  height: 300px;
  z-index: 9;
  position: relative;
  max-width: 400px;
  margin: 0 auto;
}

.logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.ant-btn-primary:hover, .ant-btn-primary:focus{
  background-color: #e6b411;
  border-color: #e6b411;
  color: #000000;
}

.ant-btn-primary{
  background-color: #D4AF37;
  border-color: #b38f1a;
  color: #0c0a00;
}

.ant-tag {
  font-size: 1em;
}

.ChangeLang {
  position: absolute;
  top: 0;
  left: 20px;
  font-size: 1em;
  font-weight: bold;
  z-index: 9;
  cursor: pointer;
}

.ant-anchor-link-title {
  color: #D4AF37;
}

.ant-anchor-link-title:hover, .ant-anchor-link-active > .ant-anchor-link-title {
  color: #ffd700;
}

.ant-anchor-ink-ball {
  border-color: #ffd700;
}

.ant-ribbon-color-purple {
  background-color: #D4AF37;
  color: #D4AF37;
}

.ant-card-meta-title {
  color: #FFD700;
}

.ant-card-meta-description {
  color: #a08f25f5;
}

.ant-divider-inner-text {
  color: #D4AF37;
}

.ant-divider-horizontal.ant-divider-with-text {
  border-color: #d4af373d;
}

.huemix-col {
  padding-top: 5%;
}

.anticon.anticon-close {
  color: #FFD700;
}

.huemix-col h3 {
  color: #FFD700;
}

.huemix-col span {
  color: #a08f25f5;
}

.ant-ribbon-text {
  color: #333205;
}

.huemix-robin.color .ant-ribbon-text {
  color: #000;
}


.ant-btn-dashed:focus:not(.extraactive), .ant-btn-dashed {
  border-color: #FFD700;
}
.ant-btn-dashed:focus:not(.extraactive), .ant-btn-dashed:focus:not(.extraactive) > span {
  color: #a08f25f5;
}

.extraactive, .ant-btn-dashed:hover {
  border-color: #FFD700 !important;
  background: #a08f25f5 !important;
  color: #000 !important;
}

.extraactive > span, .ant-btn-dashed:hover > span {
  color: #000;
}

.addons-col {
  margin-bottom: 10px;
}

.addons-col:last-child {
  margin-bottom: 0;
}

.huemix-robin .ant-ribbon-text {
  font-size: 1.3em;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 744px) {
  .huemix-categories {
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 1023px) {
  .huemix-categories .ant-anchor-link {
    font-size: 1em;
  }
}



.row-data-huemix {
  text-align: center;
}

.row-data-huemix > span {
  color: rgb(212 175 55);
}

.huemix-total {
  color: rgb(212 175 55) !important;
}